.section-hero-footer {
  @include interpolate(--section-title, sm, xxl, 20px, 24px);
  @include interpolate(--subtitle, sm, xxl, 14px, 18px);
  position: relative;
  padding: 58px 10px 0;

  @include media-breakpoint-up(sm) {
    padding: 60px 10px 30px;
  }

  @include media-breakpoint-up(md) {
    height: 100vh;
    padding: 100px 33px;
  }

  .subtitle {
    color: rgba(255, 255, 255, 0.56);
    letter-spacing: 0.04em;

    @include media-breakpoint-up(md) {
      letter-spacing: 0.02em;
    }
  }

  .section-content {
    @include media-breakpoint-up(sm) {
      margin-right: 50px;
    }
  }

  .phone {
    position: relative;
    margin-bottom: -35px;
    top: auto;
    width: 100%;
    max-width: 240px;
    margin-left: auto;
    transform: scaleX(-1);

    &-wrapper {
      @include media-breakpoint-up(sm) {
        margin-left: 50px;
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: 100%;
      margin-bottom: 0;
      margin-left: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 300px;
    }

    &-screen {
      transform: scaleX(-1);
    }
  }

  .app-links {
    margin: 24px 0;
    user-select: none;
    align-items: center !important;

    @include media-breakpoint-up(sm) {
      margin: 32px 0 0 0;
    }

    @include media-breakpoint-up(md) {
      margin: 50px 0 0 0;
    }
  }
}
