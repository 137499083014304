.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  position: relative;
  padding: 5px 0;
  user-select: none;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &-brand {
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      margin: 0 auto 0 0;
    }
    padding-top: 20px;
  }

  &.active &-menu li {
    a {
      opacity: 1;
      transform: translate(0);
    }

    @for $i from 1 through 5 { // lines
      $time: ($i) / 10;

      &:nth-child(#{$i}) a {
        transition: .2s color .3s ease-in-out, .2s opacity #{$time}s ease-in-out, .2s transform #{$time}s ease-in-out;
      }
    }
  }

  &-menu {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
    }

    li {
      display: block;

      a {
        line-height: 2.5em;
        display: block;
        padding: 0 15px;
        color: $white;
        font-size: 2rem;
        letter-spacing: 0.02em;
        opacity: 0;
        transform: translate(0, -15px);
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        will-change: text-shadow, opacity, transform;
        transition: 0s color .3s ease-in-out, 0s opacity .3s ease-in-out, 0s transform .3s ease-in-out;

        @include media-breakpoint-up(md) {
          opacity: 1;
          transform: translate(0);
          padding: 0 18px;
          font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
          padding: 0 28px;
        }

        &:hover {
          color: $blue-light;
        }
      }

      &.active a {
        font-weight: 700;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
