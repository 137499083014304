.page-kw {
  @include interpolate(--section-title, sm, xxl, 28px, 56px);
  @include interpolate(--subtitle, sm, xxl, 16px, 20px);

  main > *:first-child {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }
  }

  section {
    padding-bottom: 100px;
    padding-right: 10px;
    padding-left: 10px;
    min-width: 100%;
    min-height: 100vh;
  }

  .logos {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

    a {
      padding: 0 20px;
      display: inline-block;
    }

    a + a {
      border-left: 1px solid $white;
    }
  }

  h1 {
    max-width: 630px;
    margin: 0 auto 30px;
    font-size: var(--section-title);
  }

  p {
    line-height: 1.4;
    letter-spacing: 0.03em;
    color: rgba($white, .8);
    font-weight: 400;
    max-width: 520px;
    margin: 0 auto 30px;
  }

  p + p {
    @include media-breakpoint-up(md) {
      margin-top: 100px;
    }
  }
}
