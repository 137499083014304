html {
  margin: 0;
  padding: 0;
}

body {
  background: $white;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: hidden;
  letter-spacing: -0.01em;
  will-change: opacity;
  transition: .3s opacity ease-in-out;

  &.menu-open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

main {
  width: 100%;
  max-width: 100vw;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 20;
  position: relative;
  overflow: hidden;

  & > *:first-child {
    padding-top: 87px;

    @include media-breakpoint-up(md) {
      padding-top: calc(var(--gap) + 58px);
    }
  }
}

.bg-gradient {
  @include selection(#957dad);
}

.bg-dark {
  @include selection(#e0bbe4);
}
