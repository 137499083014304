@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;700&family=Quicksand:wght@300;400;700&display=swap');

//900 Black
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Black.woff') format('woff'),
  url('#{$path-font}/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-BlackItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

//800 extrabold
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-ExtraBold.woff') format('woff'),
  url('#{$path-font}/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-ExtraBoldItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

//700 bold
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Bold.woff') format('woff'),
  url('#{$path-font}/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-BoldItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

//600 semibold
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-SemiBold.woff') format('woff'),
  url('#{$path-font}/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-SemiBoldItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

//500 medium
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Medium.woff') format('woff'),
  url('#{$path-font}/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-MediumItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

//400 regular
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Regular.woff') format('woff'),
  url('#{$path-font}/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-RegularItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

//300 light
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Light.woff') format('woff'),
  url('#{$path-font}/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-LightItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

//200 ultralight
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-UltraLight.woff') format('woff'),
  url('#{$path-font}/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-UltraLightItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

//100 thin
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-Thin.woff') format('woff'),
  url('#{$path-font}/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('#{$path-font}/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$path-font}/Gilroy-ThinItalic.woff') format('woff'),
  url('#{$path-font}/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
