.section-signup {
  @include interpolate(--section-title, sm, xxl, 21px, 28px);
  @include interpolate(--subtitle, sm, xxl, 14px, 20px);

  padding: 75px 10px;

  @include media-breakpoint-up(md) {
    padding: 120px 10px;
  }

  .container {
    max-width: 770px;
  }

  .subtitle {
    max-width: 550px;
    color: rgba(255, 255, 255, 0.56);
    letter-spacing: 0.02em;
  }

  &.bg-gradient {
    background: linear-gradient(190.64deg, #1BAFFE -0.11%, #7628F8 117.04%);
  }

  .vlogschool-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto 0;
    max-width: 320px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 32px 0 0 0;
      max-width: 100%;
    }

    .form-control {
      max-width: 580px;

      @include media-breakpoint-down(sm) {
        height: auto;
        font-size: 16px;
        padding: 13px 16px;
      }
    }

    .btn {
      white-space: nowrap;
      margin-top: 16px;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
        padding: 18px 16px;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 24px;
      }
    }
  }
}
