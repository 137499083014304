.section-create-video {
  @include interpolate(--section-title, sm, xxl, 24px, 36px);
  @include interpolate(--subtitle, sm, xxl, 14px, 20px);

  padding: 70px 10px 100px;

  @include media-breakpoint-up(sm) {
    padding: 130px 0;
  }

  .section-title, .subtitle{
  }

  .section-title {
    margin-bottom: 8px;
  }

  .subtitle {
    margin: 0;
    color: rgba(255, 255, 255, 0.56);
  }

  ul {
    margin: 85px auto 0;
    padding: 0;
    list-style: none;
    max-width: 980px;

    @include media-breakpoint-up(sm) {
      max-width: 1020px;
    }

    li {
      max-width: 280px;

      @include media-breakpoint-up(sm) {
        max-width: 300px;
      }

      & + li {
        margin-top: 50px;
      }

      @include media-breakpoint-up(sm) {
        max-width: 250px;
        padding: 0 10px;

        & + li {
          margin-top: 0;
        }
      }
    }

    .icon {
      margin-bottom: 24px;
    }

    h6 {
      margin-top: 0;
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.08em;
    }

    p {
      color: rgba(255, 255, 255, 0.56);
    }
  }
}
