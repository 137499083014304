:root {
  @include interpolate(--h1, sm, xxl, 38px, 56px);
  @include interpolate(--h2, sm, xxl, 32px, 52px);
  @include interpolate(--h3, sm, xxl, 42px, 50px);
  @include interpolate(--h4, sm, xxl, 24px, 28px);
  @include interpolate(--h5, sm, xxl, 14px, 24px);
  @include interpolate(--h6, sm, xxl, 14px, 18px);
  @include interpolate(--p, sm, xxl, 14px, 18px);
  @include interpolate(--subtitle, sm, xxl, 16px, 24px);
  @include interpolate(--description, sm, xxl, 18px, 24px);
  @include interpolate(--small, sm, xxl, 14px, 11px);
}

h1, .h1 {
  font-size: var(--h1);
  line-height: 1;
  margin-bottom: .5em;
}

h2, .h2 {
  font-size: var(--h2);
}

h3, .h3 {
  font-size: var(--h3);
  line-height: 1;
  margin-bottom: .5em;
}

h4, .h4 {
  font-size: var(--h4);
}

h5, .h5 {
  font-size: var(--h5);
}

h6, .h6 {
  font-size: var(--h6);
}

.subtitle {
  font-size: var(--subtitle);
  font-weight: 300;
  line-height: 1.23;
}

p, .p {
  font-size: var(--p);
}

small {
  font-size: var(--small);
}

mark {
  background: transparent;
  color: $primary;
}
