.section-try {
  height: auto;
  min-height: 0;
  background: #171C31;
  padding: 50px 10px 70px;
  @include interpolate(--subtitle, sm, xxl, 16px, 20px);

  @include media-breakpoint-up(sm) {
    padding: 100px 10px 150px;
  }

  .phone-wrapper {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(sm) {
      padding-right: 10px;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 50px;
    }

    & > .phone {
      position: relative;
      bottom: auto;
      right: auto;
      transform-origin: center;
      margin-right: 0;
      margin-left: auto;

      @include media-breakpoint-down(sm) {
        margin-right: auto;
        transform: translate(0, 0) rotate(-90deg) scaleX(-1);
      }
    }
  }

  .section-content {
    min-width: 66.6666%;
    color: rgba($white, .8);

    @include media-breakpoint-up(sm) {
      padding-left: 10px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 50px;
    }

    form {
      margin-bottom: 30px;
    }

    small {
      max-width: 420px;
      letter-spacing: 0.024em;
      color: rgba($white, .8);
    }
  }

  .subtitle {
    margin-bottom: 30px;
  }
}


.select-wrapper {
  width: 85px;
  position: relative;
  color: $dark;

  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 9px;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    pointer-events: none;
  }

  &:before {
    top: 16px;
    border-top: 1px solid;
    border-left: 1px solid;
  }

  &:after {
    bottom: 16px;
    border-bottom: 1px solid;
    border-right: 1px solid;
  }
}

select.form-control {
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
  text-align: center;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $black;
  letter-spacing: 0.024em;

  @include media-breakpoint-down(sm) {
    max-width: 150px;
    width: 100%;
  }
}

.input-group {
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-left: 10px;
  }

  & > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    @include placeholder(rgba($black, .5));
    color: $black;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: .024em;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    @include media-breakpoint-down(sm) {
      border-radius: $btn-border-radius;
      max-width: 100%;
    }

    @include media-breakpoint-up(sm) {
      max-width: 150px;
    }
  }

  .btn {
    height: 48px;
    font-size: .9375rem;
    letter-spacing: .08em;
  }

  .input-group-append {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 10px;

      .btn {
        max-width: 150px;
        border-radius: $btn-border-radius;
        width: 100%;
      }
    }
  }
}
