.section-join {
  background: linear-gradient(35deg, #7628F8 0%, #1BAFFE 100%);
  @include interpolate(--section-title, sm, xxl, 18px, 32px);
  @include interpolate(--subtitle, sm, xxl, 16px, 24px);
  padding: 65px 10px;

  @include media-breakpoint-up(sm) {
    padding: 115px 65px;
  }

  @include media-breakpoint-up(md) {
    padding: 150px 10px;
  }

  .icon {
    margin-bottom: 22px;
    display: block;

    @include media-breakpoint-up(sm) {
      margin-right: 100px;
    }
  }

  .section-title {
    max-width: 280px;
    letter-spacing: -.01em;

    @include media-breakpoint-up(sm) {
      max-width: 100%;
      margin: 0 auto .5em 0;
    }
  }

  .subtitle {
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 0.02em;
    color: rgba($white, .8);
    display: block;
    max-width: 420px;
    margin: 0 0 40px;

    strong {
      font-weight: 700;
      color: $white;
    }
  }
}
