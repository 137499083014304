.slider {
  @include interpolate(--blockquote, sm, xxl, 20px, 42px);
  @include interpolate(--cite, sm, xxl, 12px, 14px);

  &-wrapper {
    position: relative;
  }

  &-item {
    padding: 0 5px;

    @include media-breakpoint-up(md) {
      padding: 0 50px;
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .testimonial {
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;
    max-width: 875px;
    margin: 0 auto;
  }

  blockquote {
    margin: 0;
    padding: 40px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      padding: 40px 30px 30px;
    }

    @include media-breakpoint-up(md) {
      padding: 50px 54px 33px;
    }

    p {
      text-align: center;
      color: $dark;
      font-size: var(--blockquote);
      font-weight: 400;
      line-height: 1.5;
      margin: 0 0 22px;
      letter-spacing: 0.015em;
    }
  }

  cite {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-style: normal;
    margin: 8px auto 0;
    font-size: var(--cite);
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.56);

    @include media-breakpoint-up(md) {
      margin-top: 33px;
    }

    span {
      display: block;
    }
  }

  .avatar {
    border-radius: 100%;
    margin-right: 30px;
    width: 80px;
    height: 80px;
    display: block;
  }

  .author {
    color: $dark;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: .18em;
    text-transform: uppercase;

    &-name {
      color: $primary;
    }

    &-position {
      color: $dark;
    }

    &-logo {
      margin-top: 10px;
    }
  }

  &-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    max-width: 1100px;
    width: 100%;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate(-50%, 0);
    pointer-events: none;

    button {
      pointer-events: auto;
      @extend .btn-none;
      height: 100%;
      padding: 12px;
      will-change: transform;
      transition: .2s transform ease-in-out;

      svg path {
        will-change: fill;
        transition: .2s fill ease-in-out;
      }

      &:hover svg path {
        fill: $primary;
      }
    }

    .slick-prev {
      &:hover {
        transform: translate(-10px, 0);
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    .slick-next:hover {
      transform: translate(10px, 0);
    }
  }

  &-dots {
    margin-top: 40px;
    align-items: center;
    justify-content: center;

    .slick-dots {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
    }

    li {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      opacity: 0.24;

      & + li {
        margin-left: 8px;
      }
    }

    li.slick-active {
      opacity: 1;
    }

    button {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
}
