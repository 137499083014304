.section-slider {
  background-color: #f1f1f1;
  @include interpolate(--section-title, sm, xxl, 16px, 14px);

  padding: 55px 0 45px;

  @include media-breakpoint-up(md) {
    padding: 120px 0 150px;
  }

  .container {
    padding: 0 25px;
  }

  .section-title {
    font-size: var(--section-title);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .2em;
    margin: 45px auto 0;
  }

  .subtitle {
    margin-bottom: 56px;

    @include media-breakpoint-up(md) {
      margin-bottom: 95px;
    }
  }
}
