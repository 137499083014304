:root {
  @include interpolate(--gap, sm, xxl, 20px, 50px);
}

.page {
  &-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 30;

    @include media-breakpoint-up(md) {
      background: transparent;
    }

    .container-fluid {
      @include media-breakpoint-up(md) {
        padding: 0 var(--gap);
      }
    }

    .collapsible {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
        position: fixed;
        top: 72px;
        left: 0;
        width: 100%;
        bottom: 0;
        display: flex;
        backdrop-filter: blur(10px);
        background-color: rgba($black, .5);
        z-index: 200;
        pointer-events: none;
        opacity: 0;
        will-change: opacity;
        transition: .3s opacity ease-in-out;
      }
    }

    .navigation.active .collapsible {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
