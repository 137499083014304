.page-support {
  background-color: #1C1D38;
}

#hubspotForm {
  background: hsla(0,0%,100%,.8);
  padding: 20px 30px 0 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 40px 0 rgba(0,0,0,.2);
  box-shadow: 0 0 40px 0 rgba(0,0,0,.2);
  margin-top: 30px;
  margin-bottom: 30px;
}
