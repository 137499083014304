.section-jump {
  position: relative;
  padding: 70px 10px;
  min-height: 100vh;
  background-position: 15% center;

  @include media-breakpoint-up(sm) {
    padding: 100px 10px 120px;
    min-height: 0;
    background-position: center;
  }

  @include media-breakpoint-up(md) {
    padding: 150px 10px 180px;
  }

  @include media-breakpoint-up(lg) {
    padding: 210px 10px 230px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: #171C31;
    opacity: 0;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  p {
    color: rgba($white, .8);
    max-width: 420px;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
