.toggle-button {
  display: block;
  position: absolute;
  right: 0;
  top: 11px;
  width: 50px;
  height: 50px;
  z-index: $zindex-fixed - 1;
  overflow: hidden;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  margin: 0 0 0 auto;
  will-change: background-color;
  transition: .2s background-color ease-in-out;

  @include media-breakpoint-up(md){
    display: none;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20%;
    min-width: 25px;
    height: 2px;
    pointer-events: none;
    background: $white;
    transform: translate(-50%, -50%);

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      will-change: transform, opacity;
      transition: .3s transform ease-in-out, .2s opacity .1s ease-in-out;
    }

    &:nth-child(1),
    &:nth-child(3) {
      will-change: transform;
      transition: .3s transform ease-in-out, .3s margin-top ease-in-out;
    }

    &:nth-child(1) {
      margin-top: -9px;
    }

    &:nth-child(3) {
      margin-top: 9px;
    }
  }

  &.active,
  .menu-open & {
    span {
      &:nth-child(1) {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translate(-50%, -50%) scaleX(0);
      }

      &:nth-child(3) {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
