//.btn-custom {
//  @include button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%));
//}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin generate-select($color) {
  position: relative;
  max-width: 680px;

  select {
    cursor: pointer;
    border: none;
    background: $white;
    outline: none;
    width: 100%;
    border-radius: $border-radius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color;
    font-size: 1rem;
    line-height: 1;
    padding: 17px 30px;
    box-shadow: 0 0 0 0 rgba($primary, .3);
    will-change: box-shadow;
    transition: .2s box-shadow ease-in-out;

    &:focus option:first-child:disabled {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 10px 3px rgba($primary, .3);
    }
  }

  .icon-angle-down-md {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: .6666rem;
    color: $color;
    transform: translate(0, -50%);
  }
}

@mixin mm($modifier) {
  $len: str-length(#{&}); /* Get parent string length */
  $parent: str-slice(#{&}, 2, $len); /* Remove leading . */

  @at-root [class^="#{$parent}"][class*="-#{$modifier}"] {
    @extend .#{$parent};
    @content;
  }
}

.btn-none {
  color: $black;
  border: none;
  background: none;
  box-shadow: none !important;
}


input {
  border: none;
  @include placeholder(rgba(0, 0, 0, 0.32));

  &:not(:placeholder-shown):invalid {
    box-shadow: 0 9px 21px 0 rgba(#100b57, .25), 0 5px 15px 0 rgba(255, 0, 0, .3);
  }

  &:valid {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &::placeholder {
    opacity: 1;
    text-indent: 0;
    transition: .3s opacity ease-in-out, .2s text-indent ease-in-out;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
      text-indent: -25px;
      transition: .2s opacity ease-in-out, .3s text-indent ease-in-out;
    }
  }
}

.btn {
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.04em;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  outline: none !important;
}

.btn-custom {
  text-transform: none;
  background-color: rgba(#3455FF, 1);
  background-image: linear-gradient(30deg, rgba(#7628F8, .45) 0%, rgba(#1BAFFE, .45) 100%);
  box-shadow: 0 19px 30px 0 rgba(#2259FF, .2);
  transition: .2s box-shadow ease-in-out;
  color: $white;

  &:hover, &:focus {
    box-shadow: 0 0 0 0 rgba(#2259FF, .1);
    color: $white;
  }
}

.btn-custom-light {
  text-transform: none;
  background-color: $white;
  box-shadow: 0 19px 30px 0 rgba(#2259FF, .2);
  transition: .2s box-shadow ease-in-out;
  color: $blue-deep;

  &:hover, &:focus {
    box-shadow: 0 0 0 0 rgba(#2259FF, .1);
    color: $blue-deep;
  }
}

.btn-custom-highlight {
  text-transform: none;
  background-color: rgba(#3455FF, 1);
  background-image: linear-gradient(30deg, rgba(#00ae40, .90) 0%, rgba(#00e706, .9) 100%);
  box-shadow: 0 19px 30px 0 rgba(#2259FF, .2);
  transition: .2s box-shadow ease-in-out;
  color: $white;

  &:hover, &:focus {
    box-shadow: 0 0 0 0 rgba(#2259FF, .1);
    color: $white;
  }
}
