.section-hero {
  @include interpolate(--subtitle, sm, xxl, 21.8px, 24px);
  position: relative;
  padding: 0 10px 50px;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include media-breakpoint-up(sm) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    padding: 100px 0;
  }

  .section-title {
    margin: 0 0 .5em 0;
    text-shadow: 0 4px 24px rgba(0, 0, 0, 0.21);
  }

  .subtitle {
    color: rgba(255, 255, 255, 0.56);
    margin: 0 0 35px;
  }

  .section-content {
    @include media-breakpoint-up(sm) {
      padding-left: 50px;
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 320px;
    }
  }

  .phone {
    margin-left: auto;
    margin-right: auto;
    width: 120px;
    min-width: 120px;

    @include media-breakpoint-up(md) {
      width: 200px;
      min-width: 200px;
    }

    @include media-breakpoint-up(lg) {
      width: 250px;
      min-width: 250px;
    }

    &-wrapper {
      min-width: 50%;
      position: relative;

      @include media-breakpoint-up(sm) {
        padding-right: 50px;
      }

      .flip-horizontal {
        position: absolute;
        bottom: 50px;
        right: -25%;
        transform-origin: bottom right;
        z-index: 1;
      }
    }

    &-screen {
      border-radius: 16px;

      @include media-breakpoint-up(md) {
        border-radius: 20px;
      }
    }
  }

  &:not(.section-try) .phone-wrapper > .phone {
    z-index: 2;
    margin-left: auto;
    margin-right: 0;
    position: relative;

    @include media-breakpoint-down(md) {
      transform: translate(50%, 0);
      margin-right: auto;
      margin-bottom: 50px;
    }

    & > *:not(.flip-horizontal) {
      z-index: 3;
    }
  }

  .arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    color: $white;
    border-bottom: 1px solid;
    border-right: 1px solid;
    cursor: pointer;
    bottom: 25px;
    width: 16px;
    height: 16px;

    @include media-breakpoint-up(md) {
      width: 30px;
      height: 30px;
      bottom: 15vh;
    }

    &:before,
    &:after {
      content: '';
      pointer-events: none;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom: 3px solid;
      border-right: 3px solid;
      opacity: 0;
      transition: .3s opacity ease-in-out;
    }

    &:before {
      animation: 3s arrows ease-in-out infinite;
    }

    &:after {
      animation: 3s arrows .4s ease-in-out infinite;
    }

    &:hover:before,
    &:hover:after {
      opacity: 0 !important;
    }
  }
}

.app-links {
  list-style: none;
  padding: 0;
  margin: 32px 0;
  user-select: none;

  @include media-breakpoint-up(sm) {
    margin: 25px 0 0 0;
  }

  @include media-breakpoint-up(md) {
    margin: 50px 0 0 0;
  }

  li:first-child {
    margin: 0 0 8px 0;

    @include media-breakpoint-up(sm) {
      margin: 0 25px 0 0;
    }
  }

  a {
    display: block;

    &.inactive {
      pointer-events: none;
    }

    img {
      display: block;
      height: 36px;

      @include media-breakpoint-up(md) {
        height: 50px;
      }
    }
  }
}

@keyframes arrows {
  0%, 50.0001% {
    transform: translate(0%, 0) scale(1);
    opacity: 0;
  }
  25%, 75% {
    opacity: .1;
  }
  50%, 100% {
    transform: translate(100%, 100%) scale(.3);
    opacity: 0;
  }
}
