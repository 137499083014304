:root {
  @include interpolate(--phone-gap, md, xxl, 40px, 149px);
}

.phone {
  position: relative;
  width: 175px;
  min-width: 175px;
  user-select: none;
  margin: 0 auto;
  transform: translate(0);
  pointer-events: none;

  @include media-breakpoint-up(sm) {
    margin: 0 40px 0 0;
  }

  @include media-breakpoint-up(md) {
    width: 250px;
    min-width: 250px;
    margin-right: var(--phone-gap);
  }

  @include media-breakpoint-up(lg) {
    width: 350px;
    min-width: 350px;
  }

  &:after {
    content: '';
    position: relative;
    display: block;
    height: 0;
    width: 0;
    padding-bottom: 200%;
  }

  &.rotate-90 {
    transform: rotate(-90deg);

    &.flip-horizontal {
      transform: translate(-50%, 0) rotate(-90deg) scaleX(-1);

      .phone-screen {
        transform: scaleY(-1);
      }
    }
  }

  &.flip-horizontal {
    transform: scaleX(-1);
  }

  &-rotate-left .phone {
    transform: rotate(-12deg);

    @include media-breakpoint-up(md) {
      transform-origin: center bottom;
    }
  }

  &-rotate-right .phone {
    transform: rotate(8deg);

    @include media-breakpoint-up(md) {
      transform-origin: center bottom;
    }
  }

  .device {
    position: absolute;
    left: -8.6%;
    top: -0.65%;
    width: 160%;
  }

  .btn-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    cursor: pointer;
    pointer-events: auto;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      opacity: 1;
    }

    &:before {
      border-left: 16px solid $white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: none;
      z-index: 2;
      margin-left: 2px;
      will-change: opcaity;
      transition: .3s opacity ease-in-out;
    }

    &:after {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background: rgba($dark, 0);
      border: 2px solid $white;
      backdrop-filter: blur(0);
      z-index: 1;
      will-change: background, transform, backdrop-filter;
      transition: .2s background ease-in-out,
      .3s backdrop-filter ease-in-out,
      .3s transform ease-in-out;
      box-shadow: 0 0 0 0 $white;
      animation: 3s pulse 0s infinite linear;
    }

    &:hover:after {
      backdrop-filter: blur(5px);
      background: rgba($primary, .2);
    }
  }

  .magnifier {
    position: absolute;
    left: 50%;
    bottom: 9%;
    transform: translate(-50%, 0);
    width: 133%;

    &-image {
      background-size: cover;
      overflow: hidden;
      border-radius: 100%;
      position: absolute;
      left: 7.5%;
      right: 7.5%;
      top: 5.65%;
      z-index: 10;

      &:after {
        content: '';
        position: relative;
        display: block;
        height: 0;
        width: 0;
        padding-bottom: 100%;
      }
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      z-index: 20;
    }
  }

  &-screen {
    position: absolute;
    left: 6.25%;
    right: 6.25%;
    top: 2.7%;
    bottom: 2.7%;
    overflow: hidden;
    border-radius: 10px;

    @include media-breakpoint-up(sm) {
      border-radius: 18px;
    }
    @include media-breakpoint-up(md) {
      border-radius: 22px;
    }
    @include media-breakpoint-up(lg) {
      border-radius: 30px;
    }

    & > img {
      display: block;
      width: 100%;
      height: auto;
      z-index: -1;
      position: relative;
      background-color: $black;
    }
  }
}

#player-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  transition: .3s opacity .2s ease-in-out;

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 70px;
    background: transparent;
    opacity: 0;
    z-index: 20;
  }

  &.active {
    z-index: 1070;
    top: 0;
    left: 0;
    opacity: 1;
    pointer-events: auto;
  }

  #player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    &-close {
      position: absolute;
      display: block;
      width: 100px;
      height: 70px;
      padding: 6px 0 0;
      background: $blue-dark;
      top: 0;
      right: 10px;
      z-index: 30;
      font-weight: 500;
      text-align: center;
      font-size: 14px;
      line-height: 1.3;
      color: $white;
      font-family: "YouTube Noto", Roboto, Arial, Helvetica, sans-serif;
      cursor: pointer;

      .player-close-icon {
        display: block;
        width: 100%;
        height: 35px;
        position: relative;

        &:before, &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 23px;
          height: 0;
          border-bottom: 3px solid $white;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}

.ytp-chrome-top-buttons {

  display: none !important;

}

.hidden-video {
  width: 0;
  height: 0;
  overflow: hidden;
}

@keyframes yt-close {
  0%, 95% {
    right: 210px;
  }
  100% {
    right: 10px;
  }
}

$pulse-size: 50;
$pulse-opacity: .5;

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($white, $pulse-opacity), 0 0 0 0 rgba($white, $pulse-opacity), 0 0 0 0 rgba($white, $pulse-opacity);
  }
  15% {
    box-shadow: 0 0 0 #{$pulse-size * .5}px rgba($white, $pulse-opacity * .5), 0 0 0 0 rgba($white, $pulse-opacity), 0 0 0 0 rgba($white, $pulse-opacity);
  }
  30% {
    box-shadow: 0 0 0 #{$pulse-size}px rgba($white, 0), 0 0 0 #{$pulse-size * .5}px rgba($white, $pulse-opacity * .5), 0 0 0 0 rgba($white, $pulse-opacity);
  }
  45% {
    box-shadow: 0 0 0 #{$pulse-size}px rgba($white, 0), 0 0 0 #{$pulse-size}px rgba($white, 0), 0 0 0 #{$pulse-size * .5}px rgba($white, $pulse-opacity * .5);
  }
  60%,
  100% {
    box-shadow: 0 0 0 #{$pulse-size}px rgba($white, 0), 0 0 0 #{$pulse-size}px rgba($white, 0), 0 0 0 #{$pulse-size}px rgba($white, 0);
  }
}
