// fonts
$quicksand: 'Quicksand', sans-serif;
$gilroy: 'Gilroy', sans-serif;

// paths
$path: (
  font: '../fonts',
  img: '../images'
);

$path-font: map-get($path, font);
$path-img: map-get($path, img);
// colors
$primary: #3455FF;
$secondary: #6046FF;
$silent: #596281;
$light: #B4C4D9;
$white: #fff;
$blue: #3A42FF;
$blue-light: #6980AB;
$blue-deep: #29359E;
$blue-dark: #040921;
$violet-light: #888CA8;
$black: #000;
$dark: #20284B;
$green: #6CA872;
$red: #F35353;

// states
$state-active: '&:hover, &:focus, &:active, &:active:hover, &:active:focus';

//3rd party assets
//bootstrap
$font-family-base: $gilroy;
$headings-font-weight: 900;
$enable-shadows: false;

//inputs
$input-line-height: 1;
$input-border-radius: 10px;
$input-border-width: 0;
$input-padding-x: 2rem;
$input-padding-y: 1rem;
$input-font-size: 1rem;

$input-line-height-lg: 1;
$input-border-radius-lg: 10px;
$input-border-width-lg: 0;
$input-padding-x-lg: 32px;
$input-padding-y-lg: 18px;
$input-font-size-lg: 1.125rem;

//buttons
$btn-border-width: 0;
$btn-font-weight: 700;

$btn-font-size-lg: 18px;
$btn-border-radius-lg: 12px;
$btn-line-height-lg: 1;
$btn-padding-x-lg: 32px;
$btn-padding-y-lg: 19px;

$btn-border-radius: 10px;
$btn-font-size: 1.0625rem;
$btn-line-height: 1;
$btn-padding-x: 26px;
$btn-padding-y: 16px;

//bootstrap:grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1380px,
  xxl: 1920px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
) !default;
