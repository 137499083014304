.section-royalty-free {
  box-shadow: inset 0 4px 100px rgba(23, 28, 49, 0.12);
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  background: $blue-dark;

  h3 {
    color: $white;
  }

  .icon {
    @include media-breakpoint-up(lg) {
      margin-left: -50px;
    }
  }

  .section-sided-row-right .section-sided-content {
    position: relative;
    text-align: left;

    @include media-breakpoint-down(md) {
      margin-bottom: 90px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 270px;
      text-align: center;
    }

    p {
      color: rgba(255, 255, 255, 0.56);
      max-width: 555px;
    }

    &:before {
      content: '';
      position: absolute;
      border-radius: 100%;
      background: rgba(#3D3D58, .4);
      z-index: 1;
      width: 1300px;
      height: 1300px;
      left: 50%;
      top: auto;
      bottom: 0;
      right: auto;
      transform: translate(-50%, 5%);

      @include media-breakpoint-up(md) {
        left: auto;
        bottom: auto;
        top: 65%;
        transform: translate(20%, -50%);
        right: 30%;
        width: 100vw;
        height: 100vw;
      }

      @include media-breakpoint-up(lg) {
        right: 25%;
        width: 80vw;
        height: 80vw;
      }

      @include media-breakpoint-up(xl) {
        right: 0;
        width: 67.5vw;
        height: 67.5vw;
      }
    }

    & > * {
      position: relative;
      z-index: 2;
    }
  }

  .collage {
    max-width: 200px;

    @include media-breakpoint-up(lg) {
      max-width: 300px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 390px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .collage-wrapper {
    margin: 0 auto 20px;
    position: relative;

    @include media-breakpoint-up(sm) {
      margin: 0 0 0 40px;
    }

    @include media-breakpoint-up(md) {
      margin-left: var(--phone-gap);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 10%;
      right: 10%;
      bottom: 10%;
      left: 10%;
    }

    &:before {
      background: #1C1D38;
      filter: blur(50px);
      opacity: .4;
      z-index: 1;
    }

    &:after {
      background: #3F3F5C;
      filter: blur(120px);
      opacity: .3;
      z-index: 2;
    }

    img {
      position: relative;
      z-index: 3;
    }
  }
}
