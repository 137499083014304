.socials {
  margin: 2px -5px 0;
  padding: 0;
  list-style: none;

  @include media-breakpoint-up(md) {
    margin: 17px -10px 0;
  }

  li + li {
    margin-left: 0;

    @include media-breakpoint-up(md) {
      margin-left: 15px;
    }
  }

  a, svg {
    display: block;
  }

  a {
    padding: 10px 5px;

    @include media-breakpoint-up(md) {
      padding: 10px;
    }

    svg {
      transform: scale(.625);

      @include media-breakpoint-up(md) {
        transform: scale(1);
      }
    }
  }

  .s-icon{
    fill: #3A42FF;
    transition: .2s fill ease-in-out;
  }

  circle {
    fill: #2D3349;
    transition: .2s fill ease-in-out;
  }

  circle ~ .s-icon {
    fill: $white !important;
  }

  a:hover {
    &.icon-facebook .s-icon,
    &.icon-facebook circle {
      fill: #3b5998;
    }

    &.icon-twitter .s-icon,
    &.icon-twitter circle {
      fill: #00acee;
    }

    &.icon-instagram .s-icon,
    &.icon-instagram circle {
      fill: #dd2a7b;
    }

    &.icon-youtube .s-icon,
    &.icon-youtube circle {
      fill: #FF0000;
    }

    &.icon-linkedin .s-icon,
    &.icon-linkedin circle {
      fill: #0e76a8;
    }
  }
}
