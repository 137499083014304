.page-footer {
  text-align: left;
  z-index: 2;
  margin-top: auto;
  margin-bottom: 0;
  padding: 60px 10px 30px;
  background: #171C31;

  @include media-breakpoint-up(md) {
    padding: 105px 10px 70px;
  }

  p {
    font-size: var(--subtitle);
    line-height: 1.5;
    letter-spacing: 0.04em;
    font-weight: 800;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  ul {
    list-style: none;
  }

  .footer-menu {
    margin: 0 0 50px 0;
    padding: 0 0 0 5px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      max-width: 100%;
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 auto;
    }

    a {
      color: $white;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 700;
      text-decoration: none !important;
      will-change: color;
      transition: .2s color ease-in-out;

      &:hover {
        color: lighten($dark, 50%);
      }
    }

    li + li {
      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 16px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 72px;
      }
    }
  }

  .socials {
    margin: 70px auto 32px;

    @include media-breakpoint-up(md) {
      margin-top: 82px;
      margin-right: 0;
      margin-bottom: 0;
    }

    a {
      padding: 0;

      svg {
        transform: none;
      }
    }

    li + li {
      margin-left: 24px;
    }
  }

  .copyright {
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.72);
    font-weight: 300;
    margin: 0;
  }

  .form-control {
    max-width: 350px;
  }

  .updates-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto 0 auto;
    max-width: 320px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 30px 0 0 0;
      max-width: 440px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }

    .btn {
      margin-top: 16px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 12px;
      }
    }
  }
}
