*, *::before, *::after {
  box-sizing: border-box;
  outline: none !important;
}

img{
  user-select: none;
}

//css vars
:root {
}
