.logo {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  white-space: nowrap;
  z-index: $zindex-fixed - 1;
  position: relative;
  font-size: 16px;
  overflow: visible;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    align-items: flex-start;
  }

  svg {
    overflow: visible;
    width: 160px;
    display: block;
    margin-left: -23px;
  }

  path {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }

  .logo-text {
    fill: #fff;
    transform-origin: center;
    transition: .2s transform cubic-bezier(1, 0, .5, 1.25);
  }

  .logo-abbr {
    fill: $primary;
    transform-origin: 30% center;
    transition: .2s transform cubic-bezier(1, 0, .5, 1.25);
  }

  &:hover {
    .logo-abbr {
      transform: scale(1.3);
    }

    .logo-text {
      transform: translate(5px, 0);
    }
  }
}
